import { type App } from 'vue';
import { pinia } from '@/plugins/store';

export interface Val {
	get: (key: string) => Promise<string>;
	set: (key: string, val: string) => Promise<void>;
}

export default {
	install(app: App) {
		const store = pinia();
		app.config.globalProperties.$val = {
			async get(key: string): Promise<string> {
				const url = new URL('/personal/', import.meta.env.VITE_API_V2 as string);
				url.searchParams.set('function', 'get_user_val');
				url.searchParams.set('session', store.getSession);
				url.searchParams.set('name', key);

				const response = await fetch(url);

				return (await response.json()).val || '';
			},
			async set(key: string, val: string): Promise<void> {
				const url = new URL('/personal/', import.meta.env.VITE_API_V2 as string);
				url.searchParams.set('function', 'store_user_val');
				url.searchParams.set('session', store.getSession);
				url.searchParams.set('name', key);
				url.searchParams.set('val', val);

				await fetch(url);
			},
		};
	},
};
