<template>
	<router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { pinia } from './plugins/store';

export default defineComponent({
	setup() {
		const store = pinia();

		return { store };
	},
	async created(): Promise<void> {
		navigator.serviceWorker
			.getRegistrations()
			.then((registrations) => {
				for (const registration of registrations) {
					registration.unregister();
				}
			})
			.catch(() => {
				// pass
			});

		setInterval(
			() => {
				this.store.createSession();
			},
			30 * 60 * 1000, // 30 min in ms
		);
	},
});
</script>

<style lang="scss">
:root {
	font-family: StarnetSans, Helvetica, Arial, sans-serif;
	color: white;
	background: #161616;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-hide {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	/* <-- Apparently some margin are still there even though it's hidden */
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}

select {
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 0.25rem;

	&.select-white {
		background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	}

	&.select-black {
		background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	}
}

.shake {
	animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
</style>
