import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import { pinia } from '@/plugins/store';

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		redirect: '/sledovat',
		component: async () => import('@/layouts/DashboardLayout.vue'),
		meta: { authRequired: true },
		children: [
			{
				name: 'Sledovat',
				path: '/sledovat',
				component: async () => import('@/views/dashboard/HomeView.vue'),
			},
			{
				name: 'Kanály',
				path: '/kanaly',
				component: async () => import('@/views/dashboard/ChannelsView.vue'),
			},
			{
				name: 'Program',
				path: '/program/:day?',
				component: async () => import('@/views/dashboard/ProgrammesView.vue'),
			},
			{
				name: 'Moje nahrávky',
				path: '/nahravky',
				component: async () => import('@/views/dashboard/RecordsView.vue'),
			},
			{
				name: 'Nabídky',
				path: '/nabidky',
				component: async () => import('@/views/dashboard/OffersView.vue'),
			},
			{
				name: 'Pořadí kanálů',
				path: '/oblibene',
				component: () => import('@/views/dashboard/FavoritesView.vue'),
			},
			{
				name: 'Hledání',
				path: '/hledani',
				component: async () => import('@/views/dashboard/SearchView.vue'),
			},
			{
				name: 'Účet',
				path: '/ucet',
				component: async () => import('@/views/dashboard/AccountView.vue'),
			},
			{
				name: 'Free',
				path: '/free',
				meta: { adminOnly: true },
				component: async () => import('@/views/dashboard/Free.vue'),
			},
			{
				name: 'Přehrání',
				path: '/sledovat/:watchType/:playId',
				component: async () => import('@/views/dashboard/WatchView.vue'),
			},
		],
	},
	{
		path: '/',
		children: [
			{
				name: 'Přihlášení',
				path: '/prihlaseni',
				meta: { disallowAuthed: true },
				component: async () => import('@/views/auth/LoginView.vue'),
			},
			{
				name: 'Odhlášení',
				path: '/odhlaseni',
				meta: { authRequired: true },
				component: async () => import('@/views/auth/LogoutView.vue'),
			},
		],
	},
	{
		name: '404 - Nenalezeno',
		path: '/:pathMatch(.*)*',
		component: async () => import('@/views/404View.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	strict: true,
	sensitive: true,
	scrollBehavior(_, __, savedPosition) {
		return savedPosition ?? { left: 0, top: 0 };
	},
	routes,
});

// Check the user's status, and route rules
router.beforeEach((to, _, next) => {
	const { name, fullPath, meta } = to;

	const hasSlash = fullPath !== '/' && fullPath.endsWith('/');
	if (hasSlash) {
		const pathWithoutSlash = fullPath.slice(0, -1);

		next({ path: pathWithoutSlash });
		return;
	}

	const store = pinia();
	if (store.isUserLogged) {
		if (meta.disallowAuthed) {
			next({ path: '/' });
			return; // Logged & disallow for authed
		}

		if (meta.adminOnly && !store.isAdmin) {
			next({ path: '/' });
			return;
		}
	} else if (meta.authRequired) {
		next({ path: '/prihlaseni' });
		return; // Not logged & auth required
	}

	document.title = `${String(name)} | Mazaná.TV`;
	next();
});

export default router;
