import { buildUrl, API_V2_BASE_URL } from '@/plugins/api';
import type { Manifest } from '@/types';

/**
 * Fetch the manifest from the API.
 * @return The manifest.
 * @throws {Error} When the API returns an error.
 */
export async function getManifest(): Promise<Manifest> {
	const url = buildUrl(API_V2_BASE_URL, {
		path: 'manifest.json.gz',
	});

	const response = await fetch(url, { redirect: 'follow' });

	if (!response.ok) {
		throw new Error('Nepodařilo se načíst manifest.');
	}

	return response.json() as Promise<Manifest>;
}
