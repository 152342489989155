import { buildUrl } from '@/plugins/api/url';
import { getManifest } from '@/plugins/api/endpoints/manifests';
import { API_V1_BASE_URL } from '@/plugins/api/constants/api';
import type { Endpoint, Manifest, UrlParams } from '@/types';

export class Api {
	public static instance: Api = new this();

	private endpoints: { [key: string]: Endpoint } = {};
	private readonly https = true;

	private constructor() {}

	/**
	 * Initialize the API.
	 */
	public async init(): Promise<void> {
		try {
			const manifest = await getManifest();
			this.processManifest(manifest);
		} catch {
			// ignore
		}
	}

	/**
	 * Get the URL for an endpoint.
	 * @param endpoint The endpoint to get the URL for.
	 * @param params The parameters to fill into the URL.
	 * @return The URL for the endpoint.
	 */
	public getEndpoint(endpoint: string, params?: UrlParams): string {
		const selection = this.endpoints[endpoint];
		if (!selection) {
			throw new Error(`Endpoint "${endpoint}" not found.`);
		}

		const baseUrl = this.selectTargetUrl(selection);
		const path = selection.url_path ?? '';
		const parameters = this.fillParameters(selection.call_template_real, params ?? {});

		return baseUrl + path + parameters;
	}

	/**
	 * Get the URL for a legacy endpoint.
	 * @param params The parameters to fill into the URL.
	 * @return The URL for the legacy endpoint.
	 */
	public getEndpointLegacy(params: UrlParams): string {
		return buildUrl(API_V1_BASE_URL, { params });
	}

	/**
	 * Select the target URL for an endpoint.
	 * @param endpoint The endpoint to select the target URL for.
	 * @return The target URL for the endpoint.
	 */
	private selectTargetUrl(endpoint: Endpoint): string {
		const preferHttps = this.https || endpoint.prefer === 'https';

		return preferHttps ? endpoint.target_https : endpoint.target_http;
	}

	/**
	 * Fill parameters into a URL.
	 * @param url The URL to fill parameters into.
	 * @param params The parameters to fill into the URL.
	 * @return The URL with the filled parameters.
	 */
	private fillParameters(url: string, params: UrlParams): string {
		for (const key in params) {
			url = url.replace(`{${key}}`, encodeURIComponent(params[key]));
		}

		return url;
	}

	/**
	 * Process the manifest and select the endpoints.
	 * @param manifest The manifest to process.
	 */
	private processManifest(manifest: Manifest): void {
		for (const key in manifest) {
			if (key === '__config') {
				continue; // ignore
			}

			let candidate: Endpoint | undefined;
			let highestProbability = 0;

			const endpoint = manifest[key];
			for (const versionKey in endpoint) {
				const version = endpoint[versionKey];

				for (const selectionKey in version) {
					const selection = version[selectionKey];
					const probability = Number(selection.prob) * Math.random();

					if (probability >= highestProbability) {
						candidate = selection;
						highestProbability = probability;
					}
				}
			}

			if (candidate) {
				this.endpoints[key] = candidate;
			}
		}
	}
}
