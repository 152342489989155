import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast, { type PluginOptions } from 'vue-toastification';
import * as Sentry from '@sentry/vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'vue-toastification/dist/index.css';
import {
	faHdd,
	faTv,
	faPlay,
	faBookOpen,
	faArrowCircleLeft,
	faBars,
	faTimes,
	faFilm,
	faSearch,
	faUpload,
	faStar,
	faTrash,
	faListAlt,
	faQuestionCircle,
	faFilter,
	faBroadcastTower,
	faRedo,
	faArrowCircleDown,
	faPlus,
	faUser,
	faBug,
	faCamera,
	faUndo,
	faGrip,
	faSmileWink,
	faCaretRight,
	faCaretLeft,
	faDotCircle,
	faPlayCircle,
	faSignOutAlt,
	faInfoCircle,
	faLink,
	faUnlink,
	faEdit,
	faCheck,
	faPause,
	faVolumeHigh,
	faVolumeXmark,
	faDownLeftAndUpRightToCenter,
	faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';
import {
	faAndroid,
	faApple,
	faFacebook,
	faInstagram,
	faLinkedinIn,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { pinia } from '@/plugins/store';
import App from '@/App.vue';
import val from '@/plugins/val';
import soap from '@/plugins/soap';
import router from '@/plugins/router';
import '@/assets/styles/index.scss';
import '@/assets/styles/font.scss';
import { Api } from '@/plugins/api';

library.add(
	faHdd,
	faTv,
	faPlay,
	faBookOpen,
	faArrowCircleLeft,
	faBars,
	faTimes,
	faFilm,
	faSearch,
	faUpload,
	faStar,
	faTrash,
	faListAlt,
	faQuestionCircle,
	faFilter,
	faBroadcastTower,
	faRedo,
	faArrowCircleDown,
	faPlus,
	faUser,
	faBug,
	faCamera,
	faUndo,
	faGrip,
	faSmileWink,
	faCaretRight,
	faCaretLeft,
	faDotCircle,
	faPlayCircle,
	faSignOutAlt,
	faInfoCircle,
	faLink,
	faUnlink,
	faEdit,
	faCheck,
	faPause,
	faVolumeHigh,
	faVolumeXmark,
	faDownLeftAndUpRightToCenter,
	faUpRightAndDownLeftFromCenter,
	faAndroid,
	faApple,
	faFacebook,
	faInstagram,
	faLinkedinIn,
	faYoutube,
);

const toastOptions: PluginOptions = {
	timeout: 3000,
	draggable: false,
	showCloseButtonOnHover: true,
};

// Reload the page if a preload error occurs.
window.addEventListener('vite:preloadError', (event) => {
	event.preventDefault();
	location.reload();
});

const app = createApp(App)
	.use(createPinia())
	.use(val)
	.use(soap)
	.use(Toast, toastOptions)
	.component('font-awesome-icon', FontAwesomeIcon);

(async () => {
	const store = pinia();
	await Api.instance.init();
	await store.fetchInternalIp();
	await store.createSession();

	Sentry.init({
		app,
		dsn: 'https://2ff66fc1d595b20617fad8f4882d0c9c@sentry-001.starnet.cz/4',
		integrations: [],
	});

	app.use(router).mount('#app');
})();
