import type { App } from '@/types/app';
import type { Epg } from '@/types';

/**
 * Generates a random MAC address.
 * @return {string} random MAC address
 */
export function generateMAC(): string {
	return 'XX:XX:XX:XX:XX:XX'.replaceAll('X', () =>
		'0123456789ABCDEF'.charAt(Math.floor(Math.random() * 16)),
	);
}

/**
 * Creates a nested object by path. Sets the value to the last object.
 * @param {Object} obj the object on which to create the hierarchy
 * @param {string[]} path strings containing the names of the objects
 * @param {Epg} val will be assigned to the end of the hierarchy
 * @example
 * // { a: { b: 'val' } }
 * assign({}, ['a', 'b'], 'val');
 */
export function assignByPath(obj: App.Chunk, path: number[], val: Epg): void {
	const lastKey = path.pop();
	if (!lastKey) return;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const lastObj: { [key: string]: Epg } = path
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		.reduce((obj, key: string) => (obj[key] ||= {}), obj);

	lastObj[lastKey] = val;
}

/**
 * Truncates the text to the specified number of characters.
 * The last word is preserved in its entirety.
 * @param {string} text text to be truncated
 * @param {number} n number of characters before truncation
 * @return {string} truncated string
 * @example
 * // hello…
 * truncate('hello world', 4);
 */
export function truncate(text?: string, n = 32): string {
	if (!text) return '';
	if (n < 0 || text.length <= n) return text;

	const subString = text.slice(0, n - 1);
	return subString.slice(0, subString.lastIndexOf(' ')) + '…';
}

export function toTitleCase(phrase: string): string {
	return phrase
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}

/**
 * Computes path hash of EPG ID.
 * @param {number} id ID of EPG
 * @return {string} computed path hash of ID
 */
export function epgHashedPath(id: number): string {
	if (!id) return '';

	const maxDirCount = 1000;

	let index = Math.floor(id / maxDirCount);
	let path = `/${id}`;
	while (index) {
		const part = index % maxDirCount;
		path = `/s${part}${path}`;
		index = Math.floor(index / maxDirCount);
	}

	return path;
}

/**
 * Convert a string to the boolean.
 * @param {0 | '0' | 1 | '1'} value The value to convert.
 * @return {boolean} The boolean value.
 */
export function toBool(value: 0 | '0' | 1 | '1'): boolean {
	return Boolean(Number(value));
}

/**
 * Round a number to the nearest multiple of another number.
 * @param value The value to round.
 * @param nearest The number to round to.
 * @return The rounded value.
 */
export function roundToNearest(value: number, nearest: number): number {
	return Math.round(value / nearest) * nearest;
}
