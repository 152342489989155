import { useToast } from 'vue-toastification';
import { setFavorite } from '@/plugins/api';
import { pinia } from '@/plugins/store';
import type { App } from 'vue';
import type { Channel } from '@/types';

export interface Soap {
	doRecord: (epgId: string | number) => Promise<void>;
	toggleFavorite: (channel: Channel) => Promise<void>;
}

export default {
	install(app: App) {
		const toast = useToast();
		const store = pinia();
		app.config.globalProperties.$soap = {
			async doRecord(epgId: string | number): Promise<void> {
				const url = new URL(import.meta.env.VITE_API_V1 as string);
				url.searchParams.set('function', 'record/do');
				url.searchParams.set('session', store.getSession);
				url.searchParams.set('source', 'epg');
				url.searchParams.set('epg_id', epgId.toString());

				const response = await fetch(url);

				if (response.ok) {
					toast.success('Pořad se začně brzy nahrávat.');
				} else {
					let message = 'Vyskytla se neznámá chyba.';
					switch (response.status) {
						case 403: {
							message = 'Nemáte oprávnění nahrát daný pořad.';
							break;
						}

						case 410: {
							message = 'Pořad již nelze nahrát.';
							break;
						}

						case 413: {
							message = 'Zakoupené místo je zaplněno.';
							break;
						}

						case 416: {
							message = 'Pořad je již nahraný.';
							break;
						}

						default: {
							break;
						}
					}

					toast.error(message);
				}
			},
			async toggleFavorite(channel: Channel): Promise<void> {
				const isFavorite = !!store.getFavorites[channel.ch_id];

				try {
					await setFavorite(channel.ch_id, !isFavorite);
					await store.fetchUserData();

					const action = isFavorite ? 'Odebráno z' : 'Přidáno do';
					toast.success(`${action} oblíbených.`);
				} catch {
					toast.error('Vyskytla se neznámá chyba.');
				}
			},
		};
	},
};
