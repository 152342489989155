import type { UrlOptions, UrlParams } from '@/types';

/**
 * Build a URL with provided base URL and options.
 * @param baseUrl The base URL to build upon.
 * @param options The options object specifying the path and parameters.
 * @return The built URL.
 */
export function buildUrl(baseUrl: string, options: UrlOptions): string {
	const path = options.path ?? '';
	const paramStr = options.params ? buildParams(options.params) : '';

	baseUrl = baseUrl.replace(/^https?/, 'https');

	if (!paramStr) {
		return `${baseUrl}${path}`;
	}

	const delimiter = baseUrl.includes('?') ? '&' : '?';

	return `${baseUrl}${path}${delimiter}${paramStr}`;
}

/**
 * Build a parameter string from an object.
 * @param params The object containing the parameter keys and values.
 * @return The parameter string.
 */
export function buildParams(params: UrlOptions['params']): string {
	if (!params) {
		return '';
	}

	const paramArr = Object.keys(params).map((key) => {
		return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
	});

	return paramArr.join('&');
}

/**
 * Replace placeholder parameters in a URL with actual values.
 * @param url - The URL string containing placeholders.
 * @param params - An object containing the parameter keys and values.
 * @return The updated URL with the placeholders replaced by the parameter values.
 */
export function fillParams(url: string, params: UrlParams): string {
	url = url.replace(/^https?/, 'http');

	return Object.keys(params).reduce((accUrl, key) => {
		return accUrl.replace(`{${key}}`, encodeURIComponent(params[key]));
	}, url);
}
